<template>
  <main class="">
    <div class="text-white py-5">
      <div class="container">
        <div class="row">
          <div class="col-md-3 text-center text-md-center mb-3">
            <img
              class="img"
              src="@/assets/images/logo-avatar/photo-rond.jpg"
              alt="Profile Photo"
            />
          </div>
          <div class="col-md-9">
            <h1>Sylvain Salliou</h1>
            <h2 class="text-dark">Testeur logiciels</h2>
            <p
              class="mt-1 border-top border-bottom pt-3 pb-3 pt-lg-5 pb-lg-5 text-center text-white"
            >
              <span
                >Après avoir travaillé dans la gestion de la paie/RH dans différents cabinets
                comptables,
              </span>
              <br />
              <span
                >j'ai saisi l'opportunité d'une reconversion pour devenir
                testeur logiciels.</span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <div class="col-md mb-5">
        <h2
          class="mb-5 p-1 colortxt bgcol text-center border border-5 border-dark rounded-pill"
        >
          Ma formation de testeur logiciels
        </h2>
        <FormationB />
        <div class="mb-5 text-dark h5">
          Avant de devenir Testeur logiciels, j'ai fait des études dans la
          comptabilité (DPECF, DECF) puis dans la gestion de la paie.
        </div>
        <h2
          class="mb-5 p-1 colortxt bgcol text-center border border-5 border-dark rounded-pill"
        >
          Certifications
        </h2>
        <Certifications />
      </div>
    </div>
  </main>
</template>

<script>
import FormationB from "@/components/cv/FormationB.vue";
import Certifications from "@/components/cv/Certifications.vue";
export default {
  components: { FormationB, Certifications },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style>
.withoutDot {
  list-style: none;
}

.colorbg {
  background-color: #1d976c;
}
.bgcol {
  background-color: #e1e6eb;
}
.colortxt {
  color: #1d976c;
}

.text-left {
  text-align: justify;
}
</style>
<style scoped lang="scss">
@import "@/assets/styles/skills.scss";
</style>
