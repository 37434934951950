<template
  ><div>
    <Navbar />

    <CV />

    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import CV from "@/components/cv/CV.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Navbar,
    CV,
    Footer
  },
  data() {
    return {};
  },
  methods: {}
};
</script>

<style></style>
