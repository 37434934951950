<template>
  <div class="section2">
    <div class="exp">
      <h2 class="border-bottom">Testeur logiciels</h2>
      <h3 class="border-bottom">2021 -> 2022</h3>
      <h3 class="mt-5 text-left">
        <div>
          <fontAwesomeIcon
            :icon="['fas', 'map-signs']"
            class="text-white ml-3 mr-1"
          /><a
            href="https://www.eni-ecole.fr/testeur-logiciels/"
            class="text-white text-decoration-none"
            target="_blank"
            >ENI <span class="d-none d-lg-block town">(Rennes)</span></a
          >
        </div>
      </h3>
    </div>

    <div class="exp-info">
      <div class="info">
        <p class="ml-2 text-left">
          <fontAwesomeIcon
            :icon="['fas', 'arrow-alt-circle-right']"
            transform="shrink-6"
            :style="{ color: 'white' }"
            class="text-start mr-3 colorbg rounded-circle"
          /><u class="colortxt">POEC Testeur logiciels</u>
        </p>
        <p class="text-justify">
          <fontAwesomeIcon
            :icon="['fas', 'ellipsis-h']"
            :style="{ color: '#198754' }"
            class="mr-3"
          />Au cours de cette formation, j'ai passé avec succès la certification
          ISTQB Foundation
        </p>
      </div>
      <div class="">
        <SkillsB />
      </div>
    </div>
  </div>
</template>

<script>
import SkillsB from "@/components/cv/SkillsB.vue";

export default {
  name: "FormationA",
  components: { SkillsB },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/assets/styles/exp.scss";
</style>
