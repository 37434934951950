<template
  ><div>
    <div class="col-md mb-3">
      <a href="#">
        <img
          class="img-fluid img-thumbnail"
          src="@/assets/images/certifications/ISTQB.png"
        />
      </a>
    </div>
    <div class="row">
      <div class="col-md mb-3">
        <a href="#">
          <img
            class="img-fluid img-thumbnail"
            src="@/assets/images/certifications/OpenClassRooms.png"
          />
        </a>
      </div>
      <div class="col-md mb-3">
        <a href="#">
          <img
            class="img-fluid img-thumbnail"
            src="@/assets/images/certifications/SecNum.png"
          />
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-md mb-3">
        <a href="@/assets/images/certifications/MongoDB.png">
          <img
            class="img-fluid img-thumbnail"
            src="@/assets/images/certifications/MongoDB.png"
          />
        </a>
      </div>
      <div class="col-md mb-3">
        <a href="@/assets/images/certifications/Alt.png">
          <img
            class="img-fluid img-thumbnail"
            src="@/assets/images/certifications/Alt.png"
          />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/skills.scss";
</style>
